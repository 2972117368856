import * as React from "react"
import { Link, type HeadFC, type PageProps } from "gatsby"

import Headers from '../components/Headers'
import Layout from "../components/Layout"



const IndexPage = ({data, pageContext}: any) => {
  return (
    <Layout lang={pageContext.lang ?? 'de'}>
        <div className="mainContent">
            <h1>404 - not found</h1>

            <p><Link to={`/`}>Home</Link></p>
        </div>
    </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
    let lang = 'en'
    let title = "404"
    if(pageContext.lang == 'de') {
        lang = 'de'
        title = "404"
    }

    return <Headers
      lang={lang}
      title={title}
      location={location}
      />
}
